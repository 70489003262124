import React, { useState } from "react";
import useWeb3 from "../hooks/useWeb3";
import useIsStarted from "../hooks/useIsStarted";
import useMint from "../hooks/useMint";
import useProofs from "../hooks/useProofs";
import RaffleMintView from "../components/raffleMintView";
import "./index.css";

export default function Raffle () {
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect,
        isConnecting,
      ] = useWeb3();

    /** TIMING */
    const [
        now,
        isStarted,
        textDay,
        textHour,
        textMin,
        textSec,
    ] = useIsStarted();

    const [
        proofs,
        isWhiteList,
        returnProofsOfUser
    ] = useProofs(address);

    const [
        isDataLoaded,
        maxMintPerUser,
        canUserMint,
        isSoldOut,
        mint,
        isMinting,
        error,
        txHash
    ] = useMint(contractSales, signer, provider, address, returnProofsOfUser())

    return (
       <RaffleMintView
            connect={connect}
            isConnected={isConnected}
            isStarted={isStarted}
            textDay={textDay}
            textHour={textHour}
            textMin={textMin}
            textSec={textSec}            
            isWhiteList={isWhiteList}
            isDataLoaded={isDataLoaded}
            canUserMint={canUserMint}
            maxMintPerUser={maxMintPerUser}
            isSoldOut={isSoldOut}
            mint={mint}
            isMinting={isMinting}
            error={error}
            txHash={txHash}
       />
    )
}