import React, { useState } from "react";
import useWeb3 from "../hooks/useWeb3";
import useIsStarted from "../hooks/useIsStarted";
import usePublicMint from "../hooks/usePublicMint";
import useProofs from "../hooks/useProofs";
import PublicMintView from "../components/publicMintView";
import "./index.css";

export default function Public () {
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect,
        isConnecting,
      ] = useWeb3();

    /** TIMING */
    const [
        now,
        isStarted,
        textDay,
        textHour,
        textMin,
        textSec,
    ] = useIsStarted();

    const [
        isDataLoaded,
        maxMintPerUser,
        canUserMint,
        isSoldOut,
        mint,
        isMinting,
        error,
        txHash
    ] = usePublicMint(contractSales, signer, provider, address)

    return (
       <PublicMintView
            connect={connect}
            isConnected={isConnected}
            isStarted={isStarted}
            textDay={textDay}
            textHour={textHour}
            textMin={textMin}
            textSec={textSec}            
            isDataLoaded={isDataLoaded}
            canUserMint={canUserMint}
            maxMintPerUser={maxMintPerUser}
            isSoldOut={isSoldOut}
            mint={mint}
            isMinting={isMinting}
            error={error}
            txHash={txHash}
       />
    )
}