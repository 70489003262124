import React, { useState, useEffect } from "react"
import Config from "../config"
import $ from 'jquery';

export default function RaffleMintView({
    connect,
    isConnected,
    isStarted,
    textDay,
    textHour,
    textMin,
    textSec,          
    isWhiteList,
    isDataLoaded,
    canUserMint,
    maxMintPerUser,
    isSoldOut,
    mint,
    isMinting,
    error,
    txHash
}) {

    const [countdownInterval, setCountdownInterval] = useState();
    const [urgencyInternal, setUrgencyInterval] = useState();

    useEffect(() => {
            var dayEvent = 23;
            var hourEvent = 17;
            var minEvent = 0;
            var secEvent = 0;
            var dCurr = new Date();
            var utcOffset1 = dCurr.getTimezoneOffset();
            var dEnd  = new Date("2022/02/23 17:00:00");
            var sethour = 16 - (utcOffset1/60);
            if (sethour<24 && sethour>0) {
            var dlast  = new Date("2022/02/23 "+ sethour +":00:00");
            } else if(sethour<0) {
                var dlast  = new Date("2022/02/22 " + (sethour + 24) +":00:00");
            } else {
                var dlast  = new Date("2022/02/24 " + (sethour - 24) +":00:00");
            }
            if (dCurr <= dlast) {
                $('.raffle-hero-subhead-e-mint-1').show();
                $('.raffle-hero-subhead-e-mint-1-2').hide();
                let interval = setInterval(function time(){
                    var d = new Date();
                    var utcOffset = d.getTimezoneOffset();   
                    var day = d.getDate();
                    var day = dayEvent - day;   
                    var hour = d.getHours();
                    var hour = Math.floor( (dEnd - d) / 3600000 );
                    var hour = hour - (utcOffset/60) - 1;
                    var hourS = String(hour);
                    if (hourS.length > 1) {
                        var hour10 = hourS.charAt(0);
                        var hour1 = hourS.charAt(1);
                    } else {
                        var hour10 = 0;
                        var hour1 = hourS.charAt(0);
                    };
                    var min = d.getMinutes();
                    if (minEvent < min) {
                        var min = 60 - (min - minEvent) - 1;
                    } else {
                        var min = minEvent - min - 1;
                    }; 
                    var minS = String(min);
                    if (minS.length > 1) {
                        var min10 = minS.charAt(0);
                        var min1 = minS.charAt(1);
                    } else {
                        var min10 = 0;
                        var min1 = minS.charAt(0);
                    };
                    var sec = d.getSeconds();
                    if (secEvent < sec) {
                        var sec = 60 - (sec - secEvent) - 1;
                    } else {
                        var sec = secEvent - sec - 1;
                    };
                    var secS = String(sec);
                    if (secS.length > 1) {
                        var sec10 = secS.charAt(0);
                        var sec1 = secS.charAt(1);
                    } else {
                        var sec10 = 0;
                        var sec1 = secS.charAt(0);
                    };
                    var hour10Val = parseInt(hour10);
                    var hour10Move = (hour10Val) * (3.25*16);
                    $(".timer-num-c-hour-10").css({top: -hour10Move});
                    var hour1Val = parseInt(hour1);
                    var hour1Move = (hour1Val) * (3.25*16);
                    $(".timer-num-c-hour-1").css({top: -hour1Move});
                    var min10Val = parseInt(min10);
                    var min10Move = (min10Val) * (3.25*16);
                    $(".timer-num-c-min-10").css({top: -min10Move});
                    var min1Val = parseInt(min1);
                    var min1Move = (min1Val) * (3.25*16);
                    $(".timer-num-c-min-1").css({top: -min1Move});
                    var sec10Val = parseInt(sec10);
                    var sec10Move = (sec10Val) * (3.25*16);
                    $(".timer-num-c-sec-10").css({top: -sec10Move});
                    var sec1Val = parseInt(sec1);
                    var sec1Move = (sec1Val) * (3.25*16);
                    $(".timer-num-c-sec-1").css({top: -sec1Move});
                }, 1000);
                setCountdownInterval(interval)
            };
            if (dCurr > dlast) {
                $(".timer-num-c-hour-10").css({top: 0});
                $(".timer-num-c-hour-1").css({top: 0});
                $(".timer-num-c-min-10").css({top: 0});
                $(".timer-num-c-min-1").css({top: 0});
                $(".timer-num-c-sec-10").css({top: 0});
                $(".timer-num-c-sec-1").css({top: 0});
            };
            
            return () => clearInterval(countdownInterval);
    }, [])

    useEffect(() => {
        if (isStarted() && isConnected) {
            clearInterval(countdownInterval);
            var btnNumber = 1;
            $('#minus-btn').on('click', function() {
                if (btnNumber > 1) {
                btnNumber -= 1;
                $(".button-number").text(btnNumber);
            }
            });
            $('#plus-btn').on('click', function() {
            if (btnNumber < 5) {
                btnNumber += 1;
                $(".button-number").text(btnNumber);
            }
            });
            var dayEvent = 23;
            var hourEvent = 19;
            var minEvent = 0;
            var secEvent = 0;
            var dCurr = new Date();
            var utcOffset1 = dCurr.getTimezoneOffset();
            var dEnd  = new Date("2022/02/23 19:00:00");
            var sethour = 18 - (utcOffset1/60);
            if (sethour<24 && sethour>0) {
            var dlast  = new Date("2022/02/23 "+ sethour +":00:00");
            } else if(sethour<0) {
            var dlast  = new Date("2022/02/22 " + (sethour + 24) +":00:00");
            } else {
                var dlast  = new Date("2022/02/24 " + (sethour - 24) +":00:00");
            }
            if (dCurr <= dlast) {
                let interval = setInterval(function time(){
                var d = new Date();
                var utcOffset = d.getTimezoneOffset();   
                var day = d.getDate();
                var day = dayEvent - day;   
                var hour = d.getHours();
                var hour = Math.floor( (dEnd - d) / 3600000 );
                var hour = hour - (utcOffset/60) - 1;
                var hourS = String(hour);
                if (hourS.length > 1) {
                    var hour10 = hourS.charAt(0);
                    var hour1 = hourS.charAt(1);
                } else {
                    var hour10 = 0;
                    var hour1 = hourS.charAt(0);
                };
                var min = d.getMinutes();
                if (minEvent < min) {
                    var min = 60 - (min - minEvent) - 1;
                } else {
                    var min = minEvent - min - 1;
                }; 
                var minS = String(min);
                if (minS.length > 1) {
                    var min10 = minS.charAt(0);
                    var min1 = minS.charAt(1);
                } else {
                    var min10 = 0;
                    var min1 = minS.charAt(0);
                };
                var sec = d.getSeconds();
                if (secEvent < sec) {
                    var sec = 60 - (sec - secEvent) - 1;
                } else {
                    var sec = secEvent - sec - 1;
                };
                var secS = String(sec);
                if (secS.length > 1) {
                    var sec10 = secS.charAt(0);
                    var sec1 = secS.charAt(1);
                } else {
                    var sec10 = 0;
                    var sec1 = secS.charAt(0);
                };
                var hour10Val = parseInt(hour10);
                var hour10Move = (hour10Val) * (3.25*16);
                $(".timer-num-c-hour-10").css({top: -hour10Move});
                var hour1Val = parseInt(hour1);
                var hour1Move = (hour1Val) * (3.25*16);
                $(".timer-num-c-hour-1").css({top: -hour1Move});
                var min10Val = parseInt(min10);
                var min10Move = (min10Val) * (3.25*16);
                $(".timer-num-c-min-10").css({top: -min10Move});
                var min1Val = parseInt(min1);
                var min1Move = (min1Val) * (3.25*16);
                $(".timer-num-c-min-1").css({top: -min1Move});
                var sec10Val = parseInt(sec10);
                var sec10Move = (sec10Val) * (3.25*16);
                $(".timer-num-c-sec-10").css({top: -sec10Move});
                var sec1Val = parseInt(sec1);
                var sec1Move = (sec1Val) * (3.25*16);
                $(".timer-num-c-sec-1").css({top: -sec1Move});
                setUrgencyInterval(interval)
            }, 1000);
            };
            if (dCurr > dlast) {
            $(".timer-num-c-hour-10").css({top: 0});
            $(".timer-num-c-hour-1").css({top: 0});
            $(".timer-num-c-min-10").css({top: 0});
            $(".timer-num-c-min-1").css({top: 0});
            $(".timer-num-c-sec-10").css({top: 0});
            $(".timer-num-c-sec-1").css({top: 0});
            };
             
             return () => clearInterval(countdownInterval)
        }
    }, [isConnected])

    const ConnectWalletSection = () => {
        return (
            <>
                {
                    !isConnected &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">MINT YOUR DOGE</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Connect Your Wallet to be able to mint</h3>
                        </div>
                        <div className="mint-1-button-w">
                            <span className="button-raffle w-button" onClick={connect}>Connect Wallet</span>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                        </div>
                    </>
                }                
            </>
        )
    }  

    const IsLoadingSection = () => {
        return (
            <>       
                 {
                    isConnected && !isDataLoaded() &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">MINT YOUR DOGE</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Your data is loading...</h3>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                        </div>
                    </>
                }          
            </>
        )
    }

    const NotSelected = () => {
        return (
            <>        
                {
                   isDataLoaded() && !isSoldOut() && !isWhiteList() &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">Sorry!</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">You have not been chosen to mint your Bored Doge</h3>
                        </div>
                        <div className="mint-1-button-w">
                            <a href={Config.URLS.opensea}  target="_blank" className="button-raffle w-button">Buy on Opensea</a>
                        </div>
                        {
                            isStarted() ?
                            <div className="mint-1-hero-subhead-timer">
                                <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER ENDS IN</h3>
                            </div>    
                            :
                            <div className="mint-1-hero-subhead-timer">
                                <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                            </div>
                        }
                    </>
                }        
            </>
        )
    }

    const NotStarted = () => {
        return (
            <>     
                {
                   isDataLoaded() && !isSoldOut() && isWhiteList() && !isStarted() &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">MINT YOUR DOGE</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Minting Starts Soon!</h3>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                        </div>
                    </>
                }            
            </>
        )
    }

    const [amount, setAmount] = useState(1);

    const incrementAmount = () => {
        if (amount < maxMintPerUser()) {
            setAmount(a => a + 1)
        }
    }

    const decrementAmount = () => {
        if (amount > 1) {
            setAmount(a => a - 1)
        }
    }

    const UserMint = () => {
        return (
            <>           
                {
                   isDataLoaded() && !isSoldOut() && isWhiteList() && isStarted() && canUserMint() &&
                    <>                            
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">CONGRATULATIONS!</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER</h3>
                        </div>
                        {
                            !isMinting &&
                            <div className="mint-2-button-w">
                                <span id="minus-btn" onClick={decrementAmount} className="mint-2-sign-c w-inline-block">
                                    <div className="mint-2-sign-b"><img src="images/minus.svg" loading="lazy" alt="" className="mint-2-sign-e"/></div>
                                </span>
                                <div className="button-mint-2-b">
                                    <span className="button-raffle button-raffle-mint-2 w-button" onClick={() => mint(amount)}>Mint  <span className="button-number first">{amount}</span>  Get  <span className="button-number second">{amount}</span>  free</span>
                                </div>
                                <span id="plus-btn" onClick={incrementAmount} className="mint-2-sign-c w-inline-block">
                                    <div className="mint-2-sign-b"><img src="images/plus.svg" loading="lazy" alt="" className="mint-2-sign-e"/></div>
                                </span>
                            </div>
                        }

                        {
                            isMinting && !error &&
                            <>
                                <div className="mint-1-hero-subhead-timer">
                                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Your mint is loading!</h3>
                                </div>
                                {
                                    txHash &&
                                    <div className="mint-1-button-w">
                                        <a href={Config.URLS.etherscan + txHash} target="_blank" className="button-raffle w-button">See Transaction</a>
                                    </div>
                                }
                            </>
                        }
                        {
                            error && 
                            <>
                                <div className="mint-1-hero-subhead-timer">
                                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1" style={{color: "red"}}>{error}</h3>
                                </div>
                            </>
                        }

                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER ENDS IN</h3>
                        </div>                         
                    </>
                }     
            </>
        )
    }

    const UserMintedAll = () => {
        return (
            <>  
             {
                 isDataLoaded() && !isSoldOut() && isWhiteList() && isStarted() && !canUserMint() &&
                 <>
                    <div className="mint-1-hero-head-b">
                        <h1 className="mint-1-hero-head-e">CONGRATULATIONS</h1>
                    </div>
                    <div className="mint-1-hero-subhead-timer">
                        <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">You have minted 5 Bored Doges.</h3>
                    </div><div className="mint-1-hero-subhead-timer">
                        <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Redirecting you to a new page...</h3>
                    </div>
                </>
             }              
            </>
        )
    }

    const SoldOutSection = () => {
        return (
            <>
                {
                     isDataLoaded() && isSoldOut() &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">Sold out!</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Bored Doge Club Mint has sold out!</h3>
                        </div>
                        <div className="mint-1-button-w">
                            <a href={Config.URLS.opensea} target="_blank" className="button-raffle w-button">Buy on Opensea</a>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                        </div>
                    </>
                }
            </>
        )
    }

    return (
        <div className="page-w">
        <div className="page-content">
          <div className="section-mint-1-hero wf-section">
            <div className="discord-hero-bg-w discord-hero-bg-w-mint-1">
              <div className="discord-hero-bg-c discord-hero-bg-c-mint-1">
                <div className="discord-hero-bg-b-1 discord-hero-bg-b-1-mint-1"><img src="images/discord-hero-bg-1.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 420.796875px, (max-width: 991px) 448px, 526px" srcset="images/discord-hero-bg-1-p-500.png 500w, images/discord-hero-bg-1.png 526w" alt="" className="discord-hero-bg-e-1 discord-hero-bg-e-1-mint-1"/></div>
                <div className="discord-hero-bg-b-2 discord-hero-bg-b-2-mint-1"><img src="images/discord-hero-bg-2.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 463.1875px, (max-width: 991px) 492.140625px, (max-width: 1919px) 579px, 30vw" srcset="images/discord-hero-bg-2-p-500.png 500w, images/discord-hero-bg-2.png 579w" alt="" className="discord-hero-bg-e-2 discord-hero-bg-e-2-mint-1"/></div>
                <div className="discord-hero-bg-b-3 discord-hero-bg-b-3-mint-1"><img src="images/raffle-hero-bg-3.png" loading="lazy" sizes="(max-width: 1439px) 100vw, 1440px" srcset="images/raffle-hero-bg-3-p-500.png 500w, images/raffle-hero-bg-3-p-800.png 800w, images/raffle-hero-bg-3-p-1080.png 1080w, images/raffle-hero-bg-3.png 1440w" alt="" className="discord-hero-bg-e-3 discord-hero-bg-e-3-mint-1"/></div>
                <div className="raffle-hero-bg-grey"></div>
              </div>
            </div>
            <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
              <div data-w-id="72d41d14-5454-c9cc-84f7-145c017696fc" className="navbar-bg-w"></div>
              <div className="page-padd">
                <div className="page-container-wide">
                  <div className="padd-vert-navbar">
                    <div className="navbar-cont-w">
                      <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                        <div className="brand-img-b"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                      </a>
                      <div className="navbar-cont-c-r">
                        <div className="navbar-button-w">
                          <div className="navbar-button-b">
                            <a href="https://discord.gg/boreddogeclub" target="_blank" className="button w-inline-block">
                              <div className="button-hover-b"></div>
                              <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/><img src="images/logo-discord-black_1logo-discord-black.png" loading="lazy" alt="" className="button-img-e-black"/></div>
                              <div className="button-text-b">
                                <div className="button-text-e">Join the Discord</div>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="menu-button w-nav-button">
                          <div className="w-icon-nav-menu"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-padd">
              <div className="page-container">
                <div className="padd-vert-discord-hero padd-vert-discord-hero-raffle-t">
                  <div className="discord-hero-cont-w discord-hero-cont-w-raffle-t">
                    <div className="discord-hero-cont-c discord-hero-cont-c-raffle-t">
                        <ConnectWalletSection />
                        <IsLoadingSection />
                        <NotSelected />
                        <NotStarted />
                        <UserMint />
                        <UserMintedAll />
                        <SoldOutSection />
                        {
                            isConnected && isStarted() &&
                            <div class="mint-1-timer-w">
                    <div class="timer-c">
                      <div class="timer-block-c">
                        <div class="timer-number-b-2">
                          <div class="timer-number-b-1">
                            <div class="timer-number-num-w">
                              <div class="num-c-hour-10">
                                <div id="timer-num-c-hour-10" class="timer-num-c-hour-10">
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="timer-number-grey-bg-10"></div>
                          </div>
                          <div class="timer-number-b-1">
                            <div class="timer-number-num-w">
                              <div class="num-c-hour-1">
                                <div id="timer-num-c-hour-1" class="timer-num-c-hour-1">
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div class="timer-text-b">
                          <div class="timer-text-e">Hours</div>
                        </div>
                      </div>
                      <div class="timer-block-c">
                        <div class="timer-number-b-2">
                          <div class="timer-number-b-1">
                            <div class="timer-number-num-w">
                              <div class="num-c-min-10">
                                <div class="timer-num-c-min-10">
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="timer-number-grey-bg-10"></div>
                          </div>
                          <div class="timer-number-b-1">
                            <div class="timer-number-num-w">
                              <div class="num-c-min-1">
                                <div class="timer-num-c-min-1">
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div class="timer-text-b">
                          <div class="timer-text-e">Minutes</div>
                        </div>
                      </div>
                      <div class="timer-block-c">
                        <div class="timer-number-b-2">
                          <div class="timer-number-b-1">
                            <div class="timer-number-num-w">
                              <div class="num-c-sec-10">
                                <div class="timer-num-c-sec-10">
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="timer-number-grey-bg-10"></div>
                          </div>
                          <div class="timer-number-b-1">
                            <div class="timer-number-num-w">
                              <div class="num-c-sec-1">
                                <div class="timer-num-c-sec-1">
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                  <div class="timer-number-num-b-1">
                                    <div class="timer-number-e">0</div>
                                    <div class="timer-number-e">1</div>
                                    <div class="timer-number-e">2</div>
                                    <div class="timer-number-e">3</div>
                                    <div class="timer-number-e">4</div>
                                    <div class="timer-number-e">5</div>
                                    <div class="timer-number-e">6</div>
                                    <div class="timer-number-e">7</div>
                                    <div class="timer-number-e">8</div>
                                    <div class="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div class="timer-text-b">
                          <div class="timer-text-e">Seconds</div>
                        </div>
                      </div>
                    </div>
                            </div>
                        }
                        {
                            (!isStarted() || !isConnected) &&
                            <div className="mint-1-timer-w">
                            <div className="timer-c">
                            <div className="timer-block-c">
                                <div className="timer-number-b-2">
                                <div className="timer-number-b-1">
                                    <div className="timer-number-num-w">
                                    <div className="num-c-hour-10">
                                        <div id="timer-num-c-hour-10" className="timer-num-c-hour-10">
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="timer-number-grey-bg-10"></div>
                                </div>
                                <div className="timer-number-b-1">
                                    <div className="timer-number-num-w">
                                    <div className="num-c-hour-1">
                                        <div id="timer-num-c-hour-1" className="timer-num-c-hour-1">
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="timer-number-grey-bg-10"></div>
                                </div>
                                </div>
                                <div className="timer-text-b">
                                <div className="timer-text-e">Hours</div>
                                </div>
                            </div>
                            <div className="timer-block-c">
                                <div className="timer-number-b-2">
                                <div className="timer-number-b-1">
                                    <div className="timer-number-num-w">
                                    <div className="num-c-min-10">
                                        <div className="timer-num-c-min-10">
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="timer-number-grey-bg-10"></div>
                                </div>
                                <div className="timer-number-b-1">
                                    <div className="timer-number-num-w">
                                    <div className="num-c-min-1">
                                        <div className="timer-num-c-min-1">
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="timer-number-grey-bg-10"></div>
                                </div>
                                </div>
                                <div className="timer-text-b">
                                <div className="timer-text-e">Minutes</div>
                                </div>
                            </div>
                            <div className="timer-block-c">
                                <div className="timer-number-b-2">
                                <div className="timer-number-b-1">
                                    <div className="timer-number-num-w">
                                    <div className="num-c-sec-10">
                                        <div className="timer-num-c-sec-10">
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="timer-number-grey-bg-10"></div>
                                </div>
                                <div className="timer-number-b-1">
                                    <div className="timer-number-num-w">
                                    <div className="num-c-sec-1">
                                        <div className="timer-num-c-sec-1">
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        <div className="timer-number-num-b-1">
                                            <div className="timer-number-e">0</div>
                                            <div className="timer-number-e">1</div>
                                            <div className="timer-number-e">2</div>
                                            <div className="timer-number-e">3</div>
                                            <div className="timer-number-e">4</div>
                                            <div className="timer-number-e">5</div>
                                            <div className="timer-number-e">6</div>
                                            <div className="timer-number-e">7</div>
                                            <div className="timer-number-e">8</div>
                                            <div className="timer-number-e">9</div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="timer-number-grey-bg-10"></div>
                                </div>
                                </div>
                                <div className="timer-text-b">
                                <div className="timer-text-e">Seconds</div>
                                </div>
                            </div>
                            </div>
                            </div>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="discord-section-footer wf-section">
            <div className="page-padd">
              <div className="page-container">
                <div className="padd-vert-footer-main padd-vert-footer-main-disc">
                  <div className="footer-cont-w">
                    <div className="footer-cont-c">
                      <div className="footer-head-b">
                        <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                          <div className="brand-img-b-footer"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                        </a>
                      </div>
                      <div className="footer-social-w">
                        <div className="footer-soc-c">
                          <div className="home-hero-soc-b">
                            <a href="https://twitter.com/boredogeclub" target="_blank" className="soc-c w-inline-block">
                              <div className="soc-b"><img src="images/logo-twitter-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-twitter-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                            </a>
                          </div>
                          <div className="home-hero-soc-b">
                            <div className="button-discord-w">
                              <div className="discord-letters-w rotate-e"></div>
                              <a href="https://discord.gg/boreddogeclub" target="_blank" className="button-discord w-inline-block">
                                <div className="button-hover-b button-hover-b-disc"></div>
                                <div className="button-discord-circle">
                                  <div className="button-discord-c">
                                    <div className="button-discord-b"><img src="images/Discord-logo-white-33_1Discord-logo-white-33.png" loading="lazy" alt="" className="button-discord-e"/></div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="home-hero-soc-b">
                            <a href="https://www.instagram.com/boreddogeclub/" target="_blank" className="soc-c w-inline-block">
                              <div className="soc-b"><img src="images/logo-insta-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-insta-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="footer-copyright-b">
                        <div className="footer-copyright-e">© 2022 Bored Doge Club NFT. All rights reserved</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}