import React, {useState, useEffect} from "react"
import Config from "../config"
import $ from 'jquery';

export default function PublicMintView({
    connect,
    isConnected,
    isStarted,
    textDay,
    textHour,
    textMin,
    textSec,          
    isWhiteList,
    isDataLoaded,
    canUserMint,
    maxMintPerUser,
    isSoldOut,
    mint,
    isMinting,
    error,
    txHash
}) {
    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        //document.dispatchEvent(new Event('readystatechange'))
      })
    const [countdownInterval, setCountdownInterval] = useState();
    const [urgencyInternal, setUrgencyInterval] = useState();

      useEffect(() => {
        if (isConnected && isDataLoaded()) {
            let toMint;
            if (amount > maxMintPerUser()) {
                toMint = maxMintPerUser();
                setAmount(toMint);
            } else {
                toMint = amount;
            }  
            mint(toMint)
        }
      }, [isConnected, isDataLoaded()])

    const ConnectWalletSection = () => {
        return (
            <>
                {
                    !isConnected &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">MINT YOUR DOGE</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Connect Your Wallet to be able to mint</h3>
                        </div>
                        <div className="mint-1-button-w">
                            <span className="button-raffle w-button" onClick={connect}>Connect Wallet</span>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                        </div>
                    </>
                }                
            </>
        )
    }  

    const IsLoadingSection = () => {
        return (
            <>       
                 {
                    isConnected && !isDataLoaded() &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">MINT YOUR DOGE</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Your data is loading...</h3>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                        </div>
                    </>
                }          
            </>
        )
    }

    const NotStarted = () => {
        return (
            <>     
                {
                   isDataLoaded() && !isSoldOut() && !isStarted() &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">MINT YOUR DOGE</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Minting Starts Soon!</h3>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                        </div>
                    </>
                }            
            </>
        )
    }

    const [amount, setAmount] = useState(1);

    const incrementAmount = () => {
        if (amount < maxMintPerUser()) {
            setAmount(a => a + 1)
        }
    }

    const decrementAmount = () => {
        if (amount > 1) {
            setAmount(a => a - 1)
        }
    }                                                                             
        
    const UserMint = () => {
            return (
            <>           
                {
                    !isSoldOut() && isStarted() && canUserMint() &&
                    <>                            
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">WHITELIST : <span className="text-span-2">SOLD OUT</span></h3>
                        </div>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">PUBLIC MINT IS <span className="text-span-3">LIVE</span> NOW</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER</h3>
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">ONLY A FEW NFTs <span className="text-span">left</span></h3>
                        </div>
                        {
                            !isMinting &&
                            <div className="mint-2-button-w">
                                <span id="minus-btn" className="mint-2-sign-c w-inline-block" onClick={decrementAmount}>
                                    <div className="mint-2-sign-b"><img src="images/minus.svg" loading="lazy" alt="" className="mint-2-sign-e"/></div>
                                </span>
                                <div className="button-mint-2-b">
                                    <span onClick={async () => {
                                        await connect();                                                                                                  
                                    }} className="button-raffle button-raffle-mint-2 w-button">Mint  <span className="button-number first">        {amount}</span>  Get  <span className="button-number second">{amount}</span>  free</span>
                                </div>
                                <span id="plus-btn"className="mint-2-sign-c w-inline-block"  onClick={incrementAmount}>
                                    <div className="mint-2-sign-b"><img src="images/plus.svg" loading="lazy" alt="" className="mint-2-sign-e"/></div>
                                </span>
                            </div>
                        }

                        {
                            isMinting && !error &&
                            <>
                                <div className="mint-1-hero-subhead-timer">
                                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Your mint is loading!</h3>
                                </div>
                                {
                                    txHash &&
                                    <div className="mint-1-button-w">
                                        <a href={Config.URLS.etherscan + txHash} target="_blank" className="button-raffle w-button">See Transaction</a>
                                    </div>
                                }
                            </>
                        }
                        {
                            error && 
                            <>
                                <div className="mint-1-hero-subhead-timer">
                                    <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1" style={{color: "red"}}>{error}</h3>
                                </div>
                            </>
                        }

                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">PRICE: 0.1 eth<br/>
                            {!isConnected && <span className="mintconnect">CONNECT YOUR WALLET TO START minting</span>}
                            </h3>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER ENDS IN</h3>
                        </div>                      
                    </>
                }     
            </>
        )
    }

    const UserMintedAll = () => {
        return (
            <>  
             {
                 isDataLoaded() && !isSoldOut() && isStarted() && !canUserMint() &&
                 <>
                    <div className="mint-1-hero-head-b">
                        <h1 className="mint-1-hero-head-e">CONGRATULATIONS</h1>
                    </div>
                    <div className="mint-1-hero-subhead-timer">
                        <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">You have minted 5 Bored Doges.</h3>
                    </div><div className="mint-1-hero-subhead-timer">
                        <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Redirecting you to a new page...</h3>
                    </div>
                </>
             }              
            </>
        )
    }

    const SoldOutSection = () => {
        return (
            <>
                {
                     isDataLoaded() && isSoldOut() &&
                    <>
                        <div className="mint-1-hero-head-b">
                            <h1 className="mint-1-hero-head-e">Sold out!</h1>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">Bored Doge Club Mint has sold out!</h3>
                        </div>
                        <div className="mint-1-button-w">
                            <a href={Config.URLS.opensea} target="_blank" className="button-raffle w-button">Buy on Opensea</a>
                        </div>
                        <div className="mint-1-hero-subhead-timer">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-1">EARLY HOLDERS OFFER STARTS IN</h3>
                        </div>
                    </>
                }
            </>
        )
    }

    return (
        <div className="page-w">
    <div className="page-content">
      <div className="section-mint-1-hero wf-section">
        <div className="discord-hero-bg-w discord-hero-bg-w-mint-1">
          <div className="discord-hero-bg-c discord-hero-bg-c-mint-1">
            <div className="discord-hero-bg-b-1 discord-hero-bg-b-1-mint-1"><img src="images/discord-hero-bg-1.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 420.7812805175781px, (max-width: 991px) 58vw, 525.9896240234375px" srcSet="images/discord-hero-bg-1-p-500.png 500w, images/discord-hero-bg-1.png 526w" alt="" className="discord-hero-bg-e-1 discord-hero-bg-e-1-mint-1"/></div>
            <div className="discord-hero-bg-b-2 discord-hero-bg-b-2-mint-1"><img src="images/discord-hero-bg-2.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 463.1944580078125px, (max-width: 991px) 64vw, (max-width: 1919px) 578.9931030273438px, 30vw" srcSet="images/discord-hero-bg-2-p-500.png 500w, images/discord-hero-bg-2.png 579w" alt="" className="discord-hero-bg-e-2 discord-hero-bg-e-2-mint-1"/></div>
            <div className="discord-hero-bg-b-3 discord-hero-bg-b-3-mint-1"><img src="images/raffle-hero-bg-3.png" loading="lazy" sizes="(max-width: 1439px) 100vw, 1440.0001220703125px" srcSet="images/raffle-hero-bg-3-p-500.png 500w, images/raffle-hero-bg-3-p-800.png 800w, images/raffle-hero-bg-3-p-1080.png 1080w, images/raffle-hero-bg-3.png 1440w" alt="" className="discord-hero-bg-e-3 discord-hero-bg-e-3-mint-1"/></div>
            <div className="raffle-hero-bg-grey"></div>
          </div>
        </div>
        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
          <div data-w-id="72d41d14-5454-c9cc-84f7-145c017696fc" className="navbar-bg-w"></div>
          <div className="page-padd">
            <div className="page-container-wide">
              <div className="padd-vert-navbar">
                <div className="navbar-cont-w">
                  <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                    <div className="brand-img-b"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                  </a>
                  <div className="navbar-cont-c-r">
                    <div className="navbar-button-w">
                      <div className="navbar-button-b">
                        <a href="https://discord.gg/boreddogeclub" target="_blank" className="button w-inline-block">
                          <div className="button-hover-b"></div>
                          <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/><img src="images/logo-discord-black_1logo-discord-black.png" loading="lazy" alt="" className="button-img-e-black"/></div>
                          <div className="button-text-b">
                            <div className="button-text-e">Join the Discord</div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="menu-button w-nav-button">
                      <div className="w-icon-nav-menu"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-padd">
          <div className="page-container">
            <div className="padd-vert-discord-hero padd-vert-discord-hero-raffle-t">
              <div className="discord-hero-cont-w discord-hero-cont-w-raffle-t">
                <div className="discord-hero-cont-c discord-hero-cont-c-raffle-t">
                  <UserMint />
                  <UserMintedAll />
                  <SoldOutSection />
                  <div className="mint-1-timer-w">
                    <div className="timer-c">
                      <div className="timer-block-c">
                        <div className="timer-number-b-2">
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-hour-10">
                                <div id="timer-num-c-hour-10" className="timer-num-c-hour-10">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-hour-1">
                                <div id="timer-num-c-hour-1" className="timer-num-c-hour-1">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div className="timer-text-b">
                          <div className="timer-text-e">Hours</div>
                        </div>
                      </div>
                      <div className="timer-block-c">
                        <div className="timer-number-b-2">
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-min-10">
                                <div className="timer-num-c-min-10">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-min-1">
                                <div className="timer-num-c-min-1">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div className="timer-text-b">
                          <div className="timer-text-e">Minutes</div>
                        </div>
                      </div>
                      <div className="timer-block-c">
                        <div className="timer-number-b-2">
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-sec-10">
                                <div className="timer-num-c-sec-10">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                          <div className="timer-number-b-1">
                            <div className="timer-number-num-w">
                              <div className="num-c-sec-1">
                                <div className="timer-num-c-sec-1">
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                  <div className="timer-number-num-b-1">
                                    <div className="timer-number-e">0</div>
                                    <div className="timer-number-e">1</div>
                                    <div className="timer-number-e">2</div>
                                    <div className="timer-number-e">3</div>
                                    <div className="timer-number-e">4</div>
                                    <div className="timer-number-e">5</div>
                                    <div className="timer-number-e">6</div>
                                    <div className="timer-number-e">7</div>
                                    <div className="timer-number-e">8</div>
                                    <div className="timer-number-e">9</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="timer-number-grey-bg-10"></div>
                          </div>
                        </div>
                        <div className="timer-text-b">
                          <div className="timer-text-e">Seconds</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="discord-section-footer wf-section">
        <div className="page-padd">
          <div className="page-container">
            <div className="padd-vert-footer-main padd-vert-footer-main-disc">
              <div className="footer-cont-w">
                <div className="footer-cont-c">
                  <div className="footer-head-b">
                    <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                      <div className="brand-img-b-footer"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                    </a>
                  </div>
                  <div className="footer-social-w">
                    <div className="footer-soc-c">
                      <div className="home-hero-soc-b">
                        <a href="https://twitter.com/boredogeclub" target="_blank" className="soc-c w-inline-block">
                          <div className="soc-b"><img src="images/logo-twitter-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-twitter-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                        </a>
                      </div>
                      <div className="home-hero-soc-b">
                        <div className="button-discord-w">
                          <div className="discord-letters-w rotate-e"></div>
                          <a href="https://discord.gg/boreddogeclub" target="_blank" className="button-discord w-inline-block">
                            <div className="button-hover-b button-hover-b-disc"></div>
                            <div className="button-discord-circle">
                              <div className="button-discord-c">
                                <div className="button-discord-b"><img src="images/Discord-logo-white-33_1Discord-logo-white-33.png" loading="lazy" alt="" className="button-discord-e"/></div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="home-hero-soc-b">
                        <a href="https://www.instagram.com/boreddogeclub/" target="_blank" className="soc-c w-inline-block">
                          <div className="soc-b"><img src="images/logo-insta-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-insta-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="footer-copyright-b">
                    <div className="footer-copyright-e">© 2022 Bored Doge Club NFT. All rights reserved</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}