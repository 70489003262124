import React, { useState, useEffect } from 'react';
import Config from "../config/index.js"
import {ethers} from 'ethers'

const PHASE = 1;

export default function useMint(contractSales, signer, provider, address, proofs) {
    const [minted, setMinted] = useState();
    const [mintedByUser, setMintedByUser] = useState();
    const [maxSupply, setMaxSupply] = useState();
    const [price, setPrice] = useState();
    
    const [error, setError] = useState();
    const [isMinting, setIsMinting] = useState();
    const [txHash, setTxHash] = useState();

    function queryContracts() {
        if (contractSales && address) {
            contractSales.mintedPermissioned()
                .then(value => setMinted(value.toNumber()))
                .catch(e => console.log(e));
            
            contractSales.addressToMints(address, PHASE)
                .then(value => setMintedByUser(value.toNumber()))
                .catch(e => console.log(e));

            contractSales.maxSupplyPermissioned()
                .then(value => setMaxSupply(value.toNumber()))
                .catch(e => console.log(e));

            contractSales.pricePermissioned()
                .then(value => setPrice(value))
                .catch(e => console.log(e));
        }
    }

    function setCorrectErrorMessage(e) {
        setError("An unexpected error occured and your transaction did not go through. Please check your wallet or etherscan to see the reason of the failure.");
        for (let error of Config.ERRORS.MESSAGES) {
            if (e.message.includes(error.error)) {
                let _msg = error.message;
                if (error.insert)
                    _msg = _msg.replace("[amount]", ethers.utils.formatEther(price.toString()))
                    setError(_msg)
                return;
            }
        }
    }

    async function mint(amount) {
        if (contractSales && signer && proofs && proofs.length > 0) {
            setError(undefined);
            try {
                setIsMinting(true)
                
                const tx = await contractSales.connect(signer).buyPermissioned(amount, proofs[0], proofs[1], { value: price.mul(amount) });
                setTxHash(tx.hash);
                await tx.wait();
                queryContracts();
            } catch (e) {
                setCorrectErrorMessage(e);
            }

            setIsMinting(false);
            setTxHash(undefined);
        } else {
            alert("wallet not loaded");
        }
    }

    function isDataLoaded() {
        return minted !== undefined &&
               mintedByUser!== undefined &&
               maxSupply !== undefined &&
               price !== undefined 
    }

    function maxMintPerUser() {
        if (isDataLoaded()) {
            return Math.max(0, Math.min(maxSupply - minted, 5 - mintedByUser))
        } else {
            return 0;
        }
    }

    function canUserMint() {
        return maxMintPerUser() > 0
    }

    function isSoldOut() {
        if (isDataLoaded()) {
            return minted >= maxSupply;
        } else {
            return false
        }
    }

    useEffect(() => {
        if (isDataLoaded() && !canUserMint()) {
            window.open("/thank-you", "_self");
        }
    }, [maxSupply, minted, mintedByUser])

    useEffect(() => {
        if (provider && contractSales && address) {
            queryContracts();
            provider.on("block", _ => {
                console.log("block update")
                queryContracts();
            })
            return () => provider.off("block");
        }
    }, [provider, contractSales, address])

    return [
        isDataLoaded,
        maxMintPerUser,
        canUserMint,
        isSoldOut,
        mint,
        isMinting,
        error,
        txHash
    ]
}