import React from "react";
import "./index.css";
import ReactPixel from 'react-facebook-pixel';
import TwitterConvTrkr from "react-twitter-conversion-tracker";
TwitterConvTrkr.init("o7tkt");

export default function ThankYou() {
    ReactPixel.trackSingle("1224903677916125", "Initiate Checkout");
    ReactPixel.trackSingle("1012736345593474", "Initiate Checkout");
    TwitterConvTrkr.pageView();
    
    return (
        <div className="page-w">
            <div className="page-content">
            <div className="section-mint-t-hero wf-section">
                <div className="discord-hero-bg-w discord-hero-bg-w-mint-t">
                <div className="discord-hero-bg-c discord-hero-bg-c-mint-t">
                    <div className="discord-hero-bg-b-1 discord-hero-bg-b-1-mint-t"><img src="images/discord-hero-bg-1.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 384px, (max-width: 991px) 448px, 480px" srcset="images/discord-hero-bg-1-p-500.png 500w, images/discord-hero-bg-1.png 526w" alt="" className="discord-hero-bg-e-1 discord-hero-bg-e-1-mint-t"/></div>
                    <div className="discord-hero-bg-b-2 discord-hero-bg-b-2-mint-t"><img src="images/discord-hero-bg-2.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 384px, (max-width: 991px) 448px, 480px" srcset="images/discord-hero-bg-2-p-500.png 500w, images/discord-hero-bg-2.png 579w" alt="" className="discord-hero-bg-e-2 discord-hero-bg-e-2-mint-t"/></div>
                    <div className="discord-hero-bg-b-3 discord-hero-bg-b-3-mint-t"><img src="images/raffle-hero-bg-3.png" loading="lazy" sizes="(max-width: 1439px) 100vw, 1440px" srcset="images/raffle-hero-bg-3-p-500.png 500w, images/raffle-hero-bg-3-p-800.png 800w, images/raffle-hero-bg-3-p-1080.png 1080w, images/raffle-hero-bg-3.png 1440w" alt="" className="discord-hero-bg-e-3 discord-hero-bg-e-3-mint-t"/></div>
                    <div className="raffle-hero-bg-grey raffle-hero-bg-grey-mint-t"></div>
                </div>
                </div>
                <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
                <div data-w-id="72d41d14-5454-c9cc-84f7-145c017696fc" className="navbar-bg-w"></div>
                <div className="page-padd">
                    <div className="page-container-wide">
                    <div className="padd-vert-navbar">
                        <div className="navbar-cont-w">
                        <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                            <div className="brand-img-b"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                        </a>
                        <div className="navbar-cont-c-r">
                            <div className="navbar-button-w">
                            <div className="navbar-button-b">
                                <a href="https://discord.gg/boreddogeclub" target="_blank" className="button w-inline-block">
                                <div className="button-hover-b"></div>
                                <div className="button-img-b"><img src="images/Discord-Logo-White_1Discord-Logo-White.png" loading="lazy" alt="" className="button-img-e"/><img src="images/logo-discord-black_1logo-discord-black.png" loading="lazy" alt="" className="button-img-e-black"/></div>
                                <div className="button-text-b">
                                    <div className="button-text-e">Join the Discord</div>
                                </div>
                                </a>
                            </div>
                            </div>
                            <div className="menu-button w-nav-button">
                            <div className="w-icon-nav-menu"></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="page-padd">
                <div className="page-container">
                    <div className="padd-vert-discord-hero padd-vert-discord-hero-mint-t">
                    <div className="discord-hero-cont-w discord-hero-cont-w-mint-t">
                        <div className="discord-hero-cont-c discord-hero-cont-c-mint-t">
                        <div className="raffle-t-hero-head-b raffle-t-hero-head-b-mint-t">
                            <h3 className="raffle-hero-head-e raffle-hero-head-e-mint-t">Congratulations!</h3>
                        </div>
                        <div className="raffle-t-hero-subhead-b raffle-t-hero-subhead-b-mint-t">
                            <h3 className="raffle-hero-subhead-timer raffle-hero-subhead-e-mint-t">You have just Minted and benefited from the EARLY HOLDERS OFFER. So for every Bored Doge NFT you have minted, you will receive 1 free on reveal date</h3>
                        </div>
                        <div className="mint-t-hero-subhead-2-b">
                            <h3 className="raffle-hero-subhead-2-e raffle-hero-subhead-2-e-mint-t">How to claim your free NFT on reveal date</h3>
                        </div>
                        <div className="mint-t-steps-w">
                            <div className="mint-t-steps-c">
                            <div id="w-node-_2d634251-5853-b85f-6417-a6d1d561227f-471f3813" className="mint-t-step-b">
                                <div className="step-num-c">
                                <div className="step-num-b"><img src="images/step-1.svg" loading="lazy" alt="" className="step-num-e"/></div>
                                </div>
                                <div className="step-text-b">
                                <div className="step-text-e">Go to <span className="text-blue">freemint.boreddogeclub.io</span><br/>and Mint using the same Metamask address.</div>
                                </div>
                            </div>
                            <div id="w-node-_6f36df59-1134-be74-1851-0ffb8e6193ae-471f3813" className="mint-t-step-b">
                                <div className="step-num-c">
                                <div className="step-num-b"><img src="images/step-2.svg" loading="lazy" alt="" className="step-num-e"/></div>
                                </div>
                                <div className="step-text-b">
                                <div className="step-text-e">The Bored Doge NFT will be free for you. You only pay the gas fee.</div>
                                </div>
                            </div>
                            <div id="w-node-_7352e8ad-40de-34d7-4fd8-b5bdee4289e4-471f3813" className="mint-t-step-b">
                                <div className="step-num-c">
                                <div className="step-num-b"><img src="images/step-3.svg" loading="lazy" alt="" className="step-num-e"/></div>
                                </div>
                                <div className="step-text-b">
                                <div className="step-text-e">Congratulations, you are now a holder of  Bored Doge NFTs.</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="mint-t-secret-b">
                            <div className="mint-text-e"><span className="text-black">LITTLE-KNOWN SECRET <br/></span> <strong>HODL 2x Bored Doge NFTs to discover something unexpected... scary... <br/>and much, much rarer... by getting your hands on the</strong></div>
                        </div>
                        <div className="mint-t-hero-subhead-2-b-2">
                            <h3 className="raffle-hero-subhead-2-e raffle-hero-subhead-2-e-mint-t">SUPER RARE MUTANT MASTIFFS!</h3>
                        </div>
                        <div className="mint-text-2-b">
                            <p className="mint-text-e">HODL your  Doges for a fortnight after the reveal to see them transform into a much more exclusive MUTANT DOGE.</p>
                        </div>
                        <div className="raffle-t-text-b raffle-t-text-b-2-mint-t">
                            <p className="raffle-t-text-e raffle-t-text-e-2-mint-t">IMPORTANT: You must use the same Metamask ETH address you used to MINT your 1st Bored Doge NFT, and must still HOLD it to that same address in order to MINT.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="discord-section-footer wf-section">
                <div className="page-padd">
                <div className="page-container">
                    <div className="padd-vert-footer-main padd-vert-footer-main-disc">
                    <div className="footer-cont-w">
                        <div className="footer-cont-c">
                        <div className="footer-head-b">
                            <a href="https://www.boreddogeclub.io/" className="brand-img-c w-nav-brand">
                            <div className="brand-img-b-footer"><img src="images/bdc-logo-2.svg" loading="lazy" alt="" className="brand-img-e"/></div>
                            </a>
                        </div>
                        <div className="footer-social-w">
                            <div className="footer-soc-c">
                            <div className="home-hero-soc-b">
                                <a href="https://twitter.com/boredogeclub" target="_blank" className="soc-c w-inline-block">
                                <div className="soc-b"><img src="images/logo-twitter-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-twitter-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                                </a>
                            </div>
                            <div className="home-hero-soc-b">
                                <div className="button-discord-w">
                                <div className="discord-letters-w rotate-e"></div>
                                <a href="https://discord.gg/boreddogeclub" target="_blank" className="button-discord w-inline-block">
                                    <div className="button-hover-b button-hover-b-disc"></div>
                                    <div className="button-discord-circle">
                                    <div className="button-discord-c">
                                        <div className="button-discord-b"><img src="images/Discord-logo-white-33_1Discord-logo-white-33.png" loading="lazy" alt="" className="button-discord-e"/></div>
                                    </div>
                                    </div>
                                </a>
                                </div>
                            </div>
                            <div className="home-hero-soc-b">
                                <a href="https://www.instagram.com/boreddogeclub/" target="_blank" className="soc-c w-inline-block">
                                <div className="soc-b"><img src="images/logo-insta-white.svg" loading="lazy" alt="" className="soc-img-e"/><img src="images/logo-insta-blue.svg" loading="lazy" alt="" className="soc-img-e-an"/></div>
                                </a>
                            </div>
                            </div>
                        </div>
                        <div className="footer-copyright-b">
                            <div className="footer-copyright-e">© 2022 Bored Doge Club NFT. All rights reserved</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}