const Config = {
    DATA: {
        MINT_START: 0,//1645632000
    },
    CONTRACTS: {
        NFT: "BoredDogeClubERC721",
        SALES: "BoredDogeClubSaleContract"
    },
    NETWORK: {
        TARGET_CHAIN_ID: 1,
        CHAIN_ID_TO_NAME: {
            "1": "mainnet",
            "3": "ropsten",
            "4": "rinkeby"
        },
        INFURA_ID: "ff5d41d5b81b4f80b325a7edcd6fe4b0",
        PORTIS_ID: "",
        FORTMATIC_ID: ""
    },
    URLS: {
        opensea: "https://opensea.io/collection/bored-doge-club",
        etherscan: "https://etherscan.io/tx/"
    },
    ERRORS: {
        MESSAGES: [
            {
                error: "HAVE TO BUY AT LEAST 1",
                message: "Error: Your transaction has not gone through. You have to buy at least one ticket"
            },
            {
                error: "MetaMask Tx Signature: User denied transaction signature",
                message: "Error: You rejected the transaction."
            },
            {
                error: "insufficient funds for gas * price + value",
                message: "Error: You don't have enough Ether in your wallet to complete the transaction. The price for one Bored Doge is [amount] Ether.",
                insert: true
            },
            {
                error: "INVALID PROOF",
                message: "Error: You are not on the white list. Please contact the team if you think this is incorrect."
            }
        ]
    }
}

export default Config