import { useState, useEffect } from 'react';
import WhiteListProofs from "../proofs/proofs.json"

export default function useProofs(address) {
    const [proofs, setProofs] = useState(undefined);

    useEffect(() => {  
        if (address) {
            let _wl = WhiteListProofs.proofs[address.toLowerCase()] || []

            setProofs(_wl);
        }
    }, [address])

    function isWhiteList() {
        return proofs && proofs.length > 0
    }

    function returnProofsOfUser() {
        return proofs
    }

    return [
        proofs,
        isWhiteList,
        returnProofsOfUser
    ]
}