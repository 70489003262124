import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Raffle from "./pages/raffle.jsx"
import Public from "./pages/public.jsx"
import ThankYou from "./pages/thankyou";

function App() {
  return (
    <div>
        <BrowserRouter>
            <Routes>
                <Route
                    path={"/"}
                    element={<Public />}
                />
                <Route
                    path={"/raffle"}
                    element={<Raffle />}
                />
                <Route
                    path={"/public"}
                    element={<Public />}
                />
                <Route
                    path={"/thank-you"}
                    element={<ThankYou />}
                />
            </Routes>
        </BrowserRouter>        
    </div>
  );
}

export default App;
